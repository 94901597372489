<template>
    <div style="height: 100%; min-width: 1200px; width: 100%; overflow: scroll;">
        <div class="mainback" :style="mainDivStyle">
            <div class="kspic">
                <img id="kspic" src="../../../assets/img/blue.jpg" width="104" height="132">
            </div>
            <div class="ksinfo">
                姓&emsp;&emsp;名：<span id="ksxm">xxx</span><br/>
                性&emsp;&emsp;别：<span id="ksxb">女</span><br/>
                准考证号：<span id="zkzh">88888888</span><br/>
                身份证号：<span id="sfzh">888888888888888888</span>
            </div>
            <div class="kstime">
                <div id="msgTip" style="display: block; font-size: 14px;margin-left: 22px;">
                    距离考试还有：
                    <span id="clock" style="font-size: 13px;">
            00:0{{ minutes }}:{{ seconds > 10 ? seconds : ('0' + seconds) }}
          </span>
                    <span style="margin-left: 3px;"><a @click="toExam" href="javascript:void(0)">进入考试</a></span>
                </div>
            </div>
            <div class="ksname"></div>
            <div class="centerText" :style="centerTextStyle">
                <section v-if="section === 0" class="section1">
                    <h5>2021年国家统一法律职业资格考试主观题考试<br>应试人员须知</h5>
                    <p>一、应试人员除携带必需文具外，不得随身携带书籍、笔记本、纸张、报刊、电子用品、存储设备及通信工具等物品进入考场。考场提供文具的，应试人员不得自行带入。</p>
                    <p>二、应试人员进入考场后，应按照座位号入座，不得擅自更换座位或离开考场；暂时离开考场，须经监考员同意；返回考场时再次接受身份核验。</p>
                    <p>三、应试人员应将本人居民身份证和准考证摆放在桌面左上角，供监考员查验。</p>
                    <p>四、 应试人员输入准考证号和居民身份证号登录考试系统，核对姓名、性别、准考证号和居民身份证号，阅读应试人员须知、应试规则和操作说明。
                    </p>
                    <p>考试开始前，应试人员可进行输入法测试，如遇键盘故障、无法正常输入等问题，可第一时间举手报告监考员。</p>
                    <p>考试开始后，计算机会自动进入答题界面。计算机屏幕右上方显示考试剩余时间。</p>
                    <p>
                        五、应试人员在考试过程中，如出现鼠标、键盘、显示屏幕故障，答卷破损、污渍等情况，影响正常答题时，应第一时间举手报告。监考员处理问题所用时间将按照规定程序给予补时。补时时长为应试人员举手报告时起，至恢复正常考试时止。</p>
                    <p>六、因应试人员自身原因导致延误答题的，不予补时。</p>
                    <p>七、 考试开始三十分钟后，应试人员不得进入考场。
                    </p>
                    <p>
                        考试结束前六十分钟内，使用计算机作答的应试人员要求提前离场的，由监考员收回草稿纸，经确认答题数据上传无误后，应试人员方可离开考场。使用纸笔作答的应试人员要求提前离场的，由监考员收回答卷、草稿纸后，应试人员方可离开考场。</p>
                    <p></p>
                    <p>
                        八、考试时间结束，应试人员应立即停止答题。使用计算机作答的，系统自动提交应试人员答题数据，监考员收回草稿纸；使用纸笔作答的，监考员统一收回应试人员的答卷、草稿纸。给予补时的，为应试人员补足时间后，由考试系统自动提交答题数据或由监考员收回答卷、草稿纸。</p>
                    <p>九、考试结束离场时，应试人员不得将答卷、草稿纸带离考场。</p>
                    <p>十、应试人员在考试期间应当严格遵守考场纪律，维护考场秩序，保持考场肃静。对应试人员的违纪行为，将按照《国家统一法律职业资格考试违纪行为处理办法》相关规定进行处理。</p>
                </section>
                <section v-if="section === 1" class="section2">
                    <h5>国家统一法律职业资格考试应试规则</h5>
                    <h6>第一章 总 则</h6>
                    <p>第一条 为了规范国家统一法律职业资格考试考场秩序，确保考试公平公正，根据《国家统一法律职业资格考试实施办法》，制定本规则。</p>
                    <p>第二条 参加国家统一法律职业资格考试的应试人员，应当遵守本规则。</p>
                    <p>第三条 应试人员应当尊重监考人员，自觉接受监考人员的监督和检查，服从监考人员的管理，不得以任何理由妨碍监考人员履行职责，不得扰乱考场秩序。</p>
                    <h6>第二章 一般规定</h6>
                    <p>第四条 应试人员应当凭准考证和居民身份证，经考务安全管理系统核验身份后进入指定考场参加考试。无准考证和居民身份证的人员不得参加考试。</p>
                    <p>第五条 应试人员在考试前四十五分钟内可以进入考场。 考试开始三十分钟后，应试人员不得进入考场。 考试结束前六十分钟内，应试人员经监考员同意后可以交卷离开考场。</p>
                    <p>第六条 应试人员应当遵守下列考试纪律：</p>
                    <p>（一）不得在考场内喧哗、走动等影响他人考试或者交卷后在考场附近喧哗等影响考试秩序;</p>
                    <p>（二）不得在考场内交头接耳、左顾右盼或者以互打手势等方式传递信息；</p>
                    <p>（三）不得与他人交换试卷（答卷），不得窥视、抄袭他人答卷或者同意他人抄袭;</p>
                    <p>（四）不得在规定时间以外答题；</p>
                    <p>（五）不得将试卷（答卷）、草稿纸和考场配发材料带出考场，不得抄写试题或者以任何形式将试题信息进行记录、存储、传输出考场；</p>
                    <p>（六）不得有其他违反考试纪律的行为。</p>
                    <p>第七条 应试人员除携带必需文具外，不得随身携带书籍、笔记本、纸张、报刊、电子用品、存储设备及通信工具等物品进入考场。考场提供文具的，应试人员不得自行带入。</p>
                    <p>第八条 应试人员进入考场后，应当对号入座，并将准考证、居民身份证放在桌面左上角，供监考员查验。</p>
                    <p>第九条 应试人员违反本规则的，由司法行政机关依据相关规定给予处理。</p> <h6>第三章 计算机化考试</h6>
                    <p>第十条
                        应试人员应当在考试开始前，根据监考员的要求和提示，登录考试系统，填写准考证号和居民身份证号，阅读考生须知、注意事项等，核对考试相关信息并进行确认。遇有无法登录、计算机系统或网络通讯故障、信息错误等情形的，应试人员应当及时向监考员报告。</p>
                    <p>第十一条 应试人员应当按照监考员的指令和规定的步骤操作计算机，使用鼠标和键盘在设定的答题区域内答题并保存答题结果，不得执行其他操作。</p>
                    <p>第十二条 考试期间，应试人员不得擅自关闭计算机、调整计算机显示屏摆放位置和角度、搬动主机箱、更换键盘和鼠标等外接设备，不得在考试机上插入硬件和安装软件。</p>
                    <p>第十三条 考试期间，应试人员不得要求监考员解释试题。如出现试题内容显示不全、识别率低、切换缓慢等情形的，可以举手报告，经监考员同意后询问。</p>
                    <p>第十四条 考试期间，如出现网络故障、电力故障、设备故障等异常情况的，应试人员应当及时向监考员报告，服从监考员安排，耐心等待解决。</p>
                    <p>非因应试人员自身原因出现的设备故障导致延误答题的，可以按规定进行补时、补考。</p>
                    <p>第十五条 应试人员因自身原因导致电子试卷下载延迟、题目漏答、考试设备损毁、电子答题数据上传有误的，应当自行承担责任。</p>
                    <p>第十六条 考试结束指令发出后，应试人员应当立即停止答题，系统自动回收电子答题数据。应试人员应当待监考员确认电子答题数据全部上传后，有序离开考场。</p>
                    <p>第十七条 应试人员没有按照要求进行登录、答题、保存电子答题数据，导致系统不能正确记录相关信息的，应当自行承担相关责任。</p> <h6>
                    第四章 纸笔考试</h6>
                    <p>第十八条 应试人员应当在考试开始后三十分钟内，按照要求在试卷（答卷）标明的位置填写（填涂）姓名、准考证号，供监考员现场查验。</p>
                    <p>应试人员不得在答卷上标记或者在非署名处署名。</p>
                    <p>第十九条 应试人员应当使用规定用笔，在答卷设定的答题区域作答。设置选作题的，应试人员可选择其一作答。</p>
                    <p>第二十条 因应试人员自身原因致使试卷（答卷）损毁、污皱的，不予更换。</p>
                    <p>第二十一条
                        因应试人员自身原因损坏试卷（答卷），填写（填涂）不清，错填、漏填姓名、准考证号，或者答题位置错误、顺序颠倒，导致无法识别姓名、准考证号，无法扫描和判读答题内容或者导致答题评分失准的，应当自行承担责任。</p>
                    <p>第二十二条 应试人员不得要求监考员解释试题，但遇有试卷（答卷）分发错误、印刷不清等问题的，可以举手并经监考员同意后询问。</p>
                    <p>第二十三条 考试结束指令发出后，应试人员应当立即停止答题，将纸质试卷（答卷）和考场配发材料放置在桌面上，经监考员核验无误后，方可离开考场。</p>
                    <h6>第五章 附 则</h6>
                    <p>第二十四条 本规则由司法部负责解释。</p>
                    <p>第二十五条 本规则自公布之日起施行。</p> <br> <br></section>
                <div v-if="section === 2" class="section">
                    <h5 style="margin-top: 0;">国家统一法律职业资格考试计算机化考试考生操作说明</h5>
                    <h6>一. 系统登录</h6>
                    <p>进入答题系统后，请根据提示输入准考证号和身份证号，点击”登录”按钮进入系统等待界面。</p>
                    <img src="../../../assets/img/image001.png" width="450" class="img">
                    <h6>二. 系统等待界面</h6>
                    <p>
                        系统等待界面是考试前的一个提示界面，登录后请认真核对屏幕左上方的基本信息。利用考前等待时间认真阅读考试重要提示（包括应试人员须知和操作说明）。点击“应试人员须知”按钮，进入应试人员须知具体说明；点击“操作说明”按钮，进入操作步骤具体说明；点击照片，可以获取最新签到照片。考试开始后，系统将自动进入应试人员答题界面。</p>
                    <img src="../../../assets/img/image002.png" class="img">
                    <h6>三. 答题界面</h6>
                    <p>答题界面上方为标题栏，下方为试题区。</p>
                    <p>(一) 标题栏</p>
                    <p>
                        标题栏中间位置为本场考试科目名称，左侧为应试人员基本信息，包括姓名、身份证号、签到照片，点击签到照片可以获取更多的应试人员信息和最新的签到照片。右侧为本场考试科目剩余时间信息和收起标题栏按钮。</p>
                    <!---->
                    <img src="../../../assets/img/image003.png" class="img">
                    <p>(二) 题号列表区</p>
                    <img src="../../../assets/img/image004.png" class="img">
                    <p>
                        题号“*”表示该小题未作答，无“*”表示该小题已作答，“？”表示该小题被标记。应试人员可以通过题号列表查看本考试科目全套试卷的作答情况（包括每道试题的已答或未答状态），还可点击各题号按钮，直接进入各试题进行答题或检查。</p>
                    <div><p>(三) 试题区域</p>
                        <p>试题区域包含资料区和答题区，在资料区可看试题内容，在答题区进行作答。试题区还有以下功能：</p>
                        <img src="../../../assets/img/image006.png" class="img">
                        <p>1.题干标识和取消标识</p>
                        <p>选中文字，点击“题干标识”按钮，文字背景变为黄色。选中被标记过的文字，点击“取消标识”按钮，文字背景恢复。</p>
                        <p>2.放大和恢复窗口</p>
                        <p>在资料区和答题区均有放大窗口功能，点击“放大窗口”可放大本区域内容可视范围，之后“放大窗口”按钮变为“恢复窗口”按钮，点击即可恢复。</p>
                        <p>3.复制和粘贴功能</p>
                        <p>
                            在答题区内，有“复制”和“粘贴”功能。选中要复制的内容，点击“复制”按钮，即完成复制操作。在作答区内，选择你要粘贴的位置，点击“粘贴”按钮，文字出现在此处，即完成粘贴操作。</p>
                        <p>4.切换输入法功能</p>
                        <p>在答题区，有切换输入法功能，点击“输入法”按钮，在按钮下方出现输入法列表，选中对应的输入法，就完成切换，且在底部工具栏显示当前的输入法。</p></div>
                    <h6>四. 结束考试</h6>
                    <p>(一) 提前交卷</p>
                    <p>如考生决定结束本考试科目作答，可以点击屏幕上方的”交卷”按钮结束本考试科目答题。</p>
                    <p>1.如所有题目均未作答，系统将提示本考试科目尚未作答，要求考生返回继续作答。</p>
                    <p>2.如考生有部分题目未作答，系统将提示本考试科目未作答题目数量，考生可返回作答或确认交卷。</p>
                    <p>
                        3.考生如连续两次确认交卷，将不能再返回本考试科目继续作答。考生在提前交卷前，请务必仔细检查答题完成情况。确认无误后，点击”确定交卷”按钮结束本考试科目答题，提交本考试科目答题结果。</p>
                    <p>4.考生在交卷后，应立即离开考场，不得在考场附近逗留、谈论和喧哗。</p>
                    <p>(二) 整场考试结束</p>
                    <p>考试时间结束仍未交卷的，系统将自动提交；考试时间内因发生异常需要补时的,系统在补足考试时间后自动提交。</p> <h6>
                    五.其他功能介绍</h6>
                    <p>
                        系统显示本考试科目剩余时间，目的是提示考生合理控制答题进度。在每科目剩余15分钟时，系统会弹出窗口提示考生；考生点击”返回作答”按钮，窗口会立即消失；如不点击，会在10秒后自动消失。</p>
                    <h4 class="shenming">本模拟答题系统旨在让应试人员熟悉计算机化考试环境和作答方式，界面及文字内容以正式考试答题系统为准。</h4> <br></div>
            </div>
            <div class="foot-bar">
                <h6 class="tongyi">
                    <input type="checkbox" name="" id="" v-model="isCheck"> 我已阅读并同意
                </h6>
                <div id="kwyq" class="kw kc" @click="section = 0">应试人员须知</div>
                <div id="czsm" class="cz kc" @click="section = 1">应试规则</div>
                <div id="ysgz" class="gz kc" @click="section = 2">操作说明</div>
            </div>
            <div class="dialog_main1" v-if="isTips">
                <div class="dialog_bt"></div>
                <div class="dialog_img"><img src="../../../assets/img/ts_2.jpg"></div>
                <ul class="dialog_content1" style="margin-left: 0; margin-right: 0;">
                    <li class="dialog_text1" style="margin-left: -20px; width: 300px;">
                        <span style="color: red;">考试即将开始,勾选左下角 “我已阅读并同意” 方可进入考试。</span>
                    </li>
                    <li style="width: 300px; padding-top: 4px;">
                        <button type="button" class="dialog_button1" @click="isTips = false">返回</button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "paperBefore",
    data() {
        return {
            section: 0,
            isCheck: false, // true / false,
            isTips: false,
            minutes: 2,
            seconds: 0,
            mainDivStyle: {
                height: '768px',
                width: '1024px'
            },
            centerTextStyle: {
                height: '516px',
                width: '929px'
            }
        }
    },
    created() {
        // 动态计算高度
        this.mainDivStyle.height = (window.innerHeight > 800) ? "768px" : "600px"
        this.mainDivStyle.width = (window.innerWidth > 1200) ? "1024px" : "800px"
        this.centerTextStyle.height = (window.innerHeight > 800) ? "516px" : "350px"
        this.centerTextStyle.width = (window.innerWidth > 1200) ? "929px" : "700px"
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#2d6dae')
    },
    mounted() {
        if (!this.$route.query.id) {
            this.$router.push({name: 'index'})
        }
        // 倒计时
        this.timer()
    },
    watch: {
        minutes(newValue) {
            if (newValue === 0) {
                this.isTips = true
            }
        }
    },
    methods: {
        // 倒计时
        timer() {
            var _this = this;
            var time = window.setInterval(function () {
                if (_this.seconds === 0 && _this.minutes !== 0) {
                    _this.seconds = 59;
                    _this.minutes -= 1;
                } else if (_this.minutes === 0 && _this.seconds === 0) {
                    _this.seconds = 0;
                    window.clearInterval(time);
                } else {
                    _this.seconds -= 1;
                }
            }, 1000);
        },
        toExam() {
            if (!this.isCheck) {
                this.isTips = true
            } else {
                this.$router.push({name: 'subjectivePaperDetail', query: {id: this.$route.query.id, sourceId: this.$route.query.sourceId, sourceType: this.$route.query.sourceType}})
            }
        }
    }
}
</script>

<style scoped>

ol, ul {
    list-style: none;
}

.dialog_button1 {
    width: 84px;
    height: 31px;
    margin-right: 40px;
    float: right;
    color: #fff;
    font-size: 14px;
    border: 0 transparent;
    cursor: pointer;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -33px -33px;
    outline: none;
}

li {
    list-style: none;
}

.dialog_text1 {
    margin-bottom: 35px;
    font-size: 18px;
    font-weight: bolder;
    line-height: 26px;
    font-family: \\5FAE\8F6F\96C5\9ED1, \\5B8B\4F53;
    text-align: justify;
}

.dialog_content1 {
    float: right;
    margin: 45px 20px 0 0;
    width: 300px;
    overflow: hidden;
}

.dialog_img {
    float: left;
    width: 130px;
    height: 135px;
    margin: 25px 0 auto 45px;
    background: url(../../../assets/img/ts_2.jpg) no-repeat;
}

.dialog_bt {
    height: 40px;
    width: 115px;
    margin: 24px auto auto 40px;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -35px -70px;
}

.dialog_main1 {
    position: absolute;
    z-index: 55;
    left: 0;
    top: -180px;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 291px;
    width: 526px;
    text-align: left;
    background: url(../../../assets/img/ts_d.png) no-repeat;
}

.shenming {
    font-weight: 700;
    text-align: left;
    text-indent: 24pt;
    line-height: 24pt;
    font-size: 12pt;
    font-family: \\5FAE\8F6F\96C5\9ED1, "sans-serif";
    color: #000;
}

.kc {
    text-align: center;
    display: inline-block;
    margin: 0 8px;
    width: 141px;
    height: 43px;
    font-size: 16px;
    line-height: 43px;
    font-weight: 700;
    color: #fff;
    border: none;
    cursor: pointer;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -156px -69px;
}

.tongyi {
    float: left;
    margin-left: 50px;
    line-height: 43px;
    font-size: 18px;
    font-family: \\534E\6587\4E2D\5B8B;
    text-align: center;
    font-weight: 400;
    margin-top: 0px;
}

.foot-bar {
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 86%;
    height: 43px;
    text-align: center;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-corner {
    background: #82AFFF;
}

::-webkit-scrollbar-thumb {
    background: rgba(50, 50, 50, 0.4);
    border-radius: 4px;
}

::selection {
    background: #2196f3;
    color: #fff;
}

section {
    display: block;
}

.centerText section h5 {
    margin-bottom: 22px;
    margin-top: 0;
}

.section h6 {
    text-indent: 8px;
    font-size: 15pt;
    font-weight: 700;
    margin: 10px 0 15px;
}

.section img {
    display: block;
    margin: auto;
    max-width: 80%;
}

.section p {
    text-align: left;
    text-indent: 24pt;
    line-height: 24pt;
    font-size: 12pt;
    font-family: \\5FAE\8F6F\96C5\9ED1, "sans-serif";
    color: #000;
}

.section2 h6 {
    font-size: 15pt;
    font-weight: bold;
    font-family: '微软雅黑';
    margin: 10px 0 15px;
    text-indent: 2em;
}

.section2 p {
    margin-bottom: 22px;
    text-indent: 30pt;
    font-family: '微软雅黑';
}

.centerText .section1 p {
    margin-bottom: 18px;
    text-indent: 30pt;
    font-family: SimSun, NSimSun, FangSong, \\5B8B\4F53;
}

.centerText .section1 {
    padding: 8px;
    font-size: 15pt;
    font-family: '楷体', '楷体_GB2312';
}

.centerText .section2 {
    padding: 8px;
    font-size: 15pt;
    font-family: '楷体', '楷体_GB2312';
}

h5 {
    font-family: '微软雅黑';
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

.centerText {
    position: absolute;
    top: 195px;
    left: 46px;
    margin: 0 auto;
    background-color: #fff;
    font-size: 12px;
    overflow: auto;
    border: 0;
    text-align: justify;
    color: #000;
}

.ksname {
    position: absolute;
    width: 598px;
    height: 31px;
    top: 150px;
    left: 212px;
    background: url(../../../assets/img/mkbgicon.png) no-repeat 0 -117px;
}

.kstime {
    position: absolute;
    top: 61px;
    right: 50px;
    width: 255px;
    height: 30px;
    color: red;
    text-align: left;
    font-size: 16px;
    font-family: '微软雅黑';
    padding-top: 10px;
    padding-left: 20px;
    font-weight: 700;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -303px -69px;
    -webkit-box-sizing: initial;
    box-sizing: initial;
}

.ksinfo {
    position: absolute;
    top: 25px;
    left: 150px;
    height: 120px;
    font-size: 18px;
    color: #0064aa;
    text-align: left;
    font-family: '宋体';
    font-weight: 700;
    line-height: 30px;
    word-spacing: 2px;
}

.kspic {
    position: absolute;
    top: 20px;
    left: 40px;
    width: 100px;
    height: 120px;
    cursor: pointer;
}

img {
    display: block;
    max-width: 100%;
    border: none;
}

.mainback {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    -webkit-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translateX(-50%) translateY(-50%);
    background: url(../../../assets/img/paperChoose-bg.jpg) no-repeat;
}

body {
    min-width: 1200px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
</style>